import { Component, OnInit, ViewChild } from '@angular/core';
import { OwlCarousel } from 'ngx-owl-carousel';

@Component({
    selector: 'app-partners',
    templateUrl: './partners.component.html',
    styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {

    @ViewChild('owlElement') owlElement: OwlCarousel

    mySlideImages = [
        'assets/img/horvathrudolf.jpg',
        'assets/img/hungarocargo.jpg',
        'assets/img/kn.jpg',
        'assets/img/waberer.jpg',
        'assets/img/mgm.png',
        'assets/img/c4logistics.jpg',
        'assets/img/flash-europe-logo.jpg',
        'assets/img/intime.jpg'
    ];
    mySlideOptions = { items: 3, dots: false, nav: false, loop: true };

    constructor() { }

    ngOnInit() {
        this.slideCarousel();
    }

    slideCarousel() {
        setTimeout(() => {
            this.owlElement.next([2000]);
            this.slideCarousel();
        }, 2000);
    }
}
