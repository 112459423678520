import { Component, OnInit, Inject, Renderer, ElementRef, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/filter';
import { DOCUMENT } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { NavbarComponent } from './shared/navbar/navbar.component';

import AOS from 'aos';
import 'aos/dist/aos.css';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    private _router: Subscription;
    private prevY = 0;
    @ViewChild(NavbarComponent) navbar: NavbarComponent;
    public shouldShow = true;

    constructor(
        private renderer: Renderer,
        private router: Router,
        @Inject(DOCUMENT) private document: any,
        private element: ElementRef,
        public location: Location,
        public translate: TranslateService
    ) { }
    ngOnInit() {
        this.translate.setDefaultLang('hu');
        this.translate.use('hu');

        this.shouldShow = location.pathname !== '/fonok'

        AOS.init({ duration: 3000 });

        this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((_event: NavigationEnd) => {
            if (window.outerWidth > 991) {
                window.document.children[0].scrollTop = 0;
            } else {
                window.document.activeElement.scrollTop = 0;
            }

            const navbar: HTMLElement = this.element.nativeElement.children[0].children[0];

            if (navbar) {
                this.navbar.sidebarClose();

                this.renderer.listenGlobal('window', 'scroll', (event: any) => {
                    if (window.scrollY > window.innerHeight * 0.75 || window.pageYOffset > window.innerHeight * 0.75) {
                        if (!(<any>this.navbar).sidebarVisible && window.scrollY > this.prevY) {
                            navbar.classList.add('navbar-hidden');
                        } else {
                            navbar.classList.remove('navbar-hidden');
                        }
                        navbar.classList.remove('navbar-transparent');
                    } else {
                        navbar.classList.add('navbar-transparent');
                    }

                    this.prevY = window.scrollY;
                });
            }
        });
    }
}
