import { Component, OnInit } from '@angular/core';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';
import { PhotoService } from 'app/services/photo.service';
import { Photo } from 'app/admin/photos/photo';
import { AngularFireStorage } from '@angular/fire/storage';

@Component({
    selector: 'app-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {

    galleryOptions: NgxGalleryOptions[];
    galleryImages: NgxGalleryImage[];

    constructor(private photoService: PhotoService, private storage: AngularFireStorage) {
        this.photoService.getAll().subscribe(data => {
            this.galleryImages = [];

            const photoRefs = data.map(e => {
                return {
                    id: e.payload.doc.id,
                    ...e.payload.doc.data()
                } as Photo;
            });

            photoRefs.map((file) => {
                this.storage.ref(file.path).getDownloadURL().subscribe(x => this.galleryImages.push(
                    {
                        small: x,
                        medium: x,
                        big: x
                    }
                ));
            });
        });
    }

    ngOnInit(): void {

        this.galleryOptions = [
            {
                width: '600px',
                height: '400px',
                thumbnailsColumns: 4,
                imageAnimation: NgxGalleryAnimation.Zoom,
                thumbnailsMoveSize: 4,
                imageAutoPlay: true,
                imageAutoPlayPauseOnHover: true,
                previewFullscreen: true,
                previewKeyboardNavigation: true,
                previewCloseOnEsc: true,
                previewZoom: true,
                previewRotate: true,
                imageSwipe: true,
                thumbnailsSwipe: true,
                previewSwipe: true,
                imageArrowsAutoHide: true,
                thumbnailsArrowsAutoHide: true,
                imageInfinityMove: true
            },
            // max-width 800
            {
                breakpoint: 800,
                width: '100%',
                height: '600px',
                imagePercent: 80,
                thumbnailsPercent: 20,
                thumbnailsMargin: 20,
                thumbnailMargin: 20
            },
            // max-width 400
            {
                breakpoint: 400,
                preview: false
            }
        ];
    }
}
