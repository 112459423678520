import { Component } from '@angular/core';
import * as moment from 'moment';
import { AngularFireFunctions } from '@angular/fire/functions';
import { MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-offer',
    templateUrl: './offer.component.html',
    styleUrls: ['./offer.component.scss']
})
export class OfferComponent {
    today = new Date();
    name: string;
    address: string;
    contact: string;
    phone: string;
    email: string;
    count: string;
    date: Date;
    details: string;
    originName: string;
    originAddress: string;
    destName: string;
    destAddress: string;
    pleaseFillField = 'Kérem töltse ki ezt a mezőt';
    isProcessing = false;
    isSent = false;

    constructor(private fns: AngularFireFunctions, private snackBar: MatSnackBar, private translate: TranslateService) { }

    send({ value, valid }) {
        if (!valid) {
            this.translate.get('OFFER.FILL').subscribe((text: string) => {
                this.snackBar.open(text, undefined, {
                    duration: 4000,
                    panelClass: ['error-snackbar']
                });
            });

            return;
        }

        Object.keys(value).forEach(function (key) {
            value[key] = value[key] === undefined ? '-' : value[key]
        });

        value.date = moment(value.date).format('YYYY-MM-DD');

        const callable = this.fns.httpsCallable('fireStoreEmail');
        const func = callable(value);

        this.isProcessing = true;

        func.subscribe(res => {
            this.isProcessing = false;

            if (res === 200) {
                this.translate.get('OFFER.SUCCESS').subscribe((text: string) => {
                    this.snackBar.open(text, undefined, {
                        duration: 4000,
                        panelClass: ['success-snackbar']
                    });
                });

                this.isSent = true;
            } else {
                this.translate.get('OFFER.ERROR').subscribe((text: string) => {
                    this.snackBar.open(text, undefined, {
                        duration: 10000,
                        panelClass: ['warning-snackbar']
                    });
                });
            }
        });
    }
}
