import { Component, OnInit } from '@angular/core';
import { JobService } from 'app/services/job.service';

@Component({
    selector: 'app-jobs',
    templateUrl: './jobs.component.html',
    styleUrls: ['./jobs.component.scss']
})
export class JobsComponent implements OnInit {
    jobs: Job[] = [];

    constructor(public jobService: JobService) { }

    ngOnInit() {
        this.jobService.getAll().subscribe(data => {
            this.jobs = data.map(e => {
                return {
                    id: e.payload.doc.id,
                    ...e.payload.doc.data()
                } as Job;
            });
        });
    }
}
