import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-ginop',
    templateUrl: './ginop.component.html',
    styleUrls: ['./ginop.component.scss']
})
export class GinopComponent implements OnInit {
    constructor() { }

    ngOnInit() { }

}
