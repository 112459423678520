// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    firebaseConfig: {
        apiKey: 'AIzaSyAYlpcmlB4baZC7RLePNQ7hJtyoqYUNFe0',
        authDomain: 'gigi-ded4a.firebaseapp.com',
        databaseURL: 'https://gigi-ded4a.firebaseio.com',
        projectId: 'gigi-ded4a',
        storageBucket: 'gs://gigi-ded4a.appspot.com/',
        messagingSenderId: '102091741006'
    }
};
