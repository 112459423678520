import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root'
})
export class JobService {
    constructor(private firestore: AngularFirestore) { }

    create(job: Job) {
        return this.firestore.collection('jobs').add(job);
    }

    getAll() {
        return this.firestore.collection('jobs').snapshotChanges();
    }

    update(job: Job) {
        this.firestore.doc('jobs/' + job.id).update(job);
    }

    delete(id: string) {
        this.firestore.doc('jobs/' + id).delete();
    }
}
