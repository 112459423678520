import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-service',
    templateUrl: './service.component.html',
    styleUrls: ['./service.component.scss']
})
export class ServiceComponent implements OnInit {
    background: string;
    backgrounds = new Array(
        'assets/img/q_service_cover_1.png'
    );
    current = 0;

    constructor() { }

    ngOnInit() {
        setInterval(this.nextBackground.bind(this), 5000);
        this.background = this.backgrounds[0];
    }

    nextBackground() {
        this.current++;
        this.current = this.current % this.backgrounds.length;
        this.background = this.backgrounds[this.current];
    }
}
