import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { NouisliderModule } from 'ng2-nouislider';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { RouterModule } from '@angular/router';

import {
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatProgressSpinnerModule
} from '@angular/material';

import { ComponentsComponent } from './components.component';
import { AboutComponent } from './about/about.component';
import { MglTimelineModule } from 'angular-mgl-timeline';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { TeamComponent } from './team/team.component';
import { VehiclesComponent } from './vehicles/vehicles.component';
import { PartnersComponent } from './partners/partners.component';
import { OfferComponent } from './offer/offer.component';
import { ContactComponent } from './contact/contact.component';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { JobsComponent } from './jobs/jobs.component';
import { ServiceComponent } from './service/service.component';
import { OwlModule } from 'ngx-owl-carousel';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { GalleryComponent } from './gallery/gallery.component';
import { NgxGalleryModule } from 'ngx-gallery';
import { GinopComponent } from './ginop/ginop.component';

@NgModule({
    imports: [
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        FormsModule,
        NgbModule,
        NouisliderModule,
        RouterModule,
        JwBootstrapSwitchNg2Module,
        MglTimelineModule,
        ScrollToModule,
        OwlModule,
        MatButtonModule,
        MatInputModule,
        MatIconModule,
        MatDatepickerModule,
        MatNativeDateModule,
        AngularFireFunctionsModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        TranslateModule.forChild(),
        NgxGalleryModule
    ],
    declarations: [
        ComponentsComponent,
        AboutComponent,
        TeamComponent,
        VehiclesComponent,
        PartnersComponent,
        OfferComponent,
        ContactComponent,
        JobsComponent,
        ServiceComponent,
        GalleryComponent,
        GinopComponent
    ],
    exports: [ComponentsComponent]
})
export class ComponentsModule { }
