import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit, AfterViewInit {
    @ViewChild('entryComponent') entryComponent;
    @ViewChild('entryComponent2') entryComponent2;
    @ViewChild('entryComponent3') entryComponent3;
    @ViewChild('entryComponent4') entryComponent4;
    size = 40;

    constructor() { }

    ngOnInit() { }

    ngAfterViewInit() {
        const path = [{ localName: 'mgl-timeline-entry-header' }]
        this.entryComponent.toggle({ path });
        this.entryComponent2.toggle({ path });
        this.entryComponent3.toggle({ path });
        this.entryComponent4.toggle({ path });
    }
}
