import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-components',
    templateUrl: './components.component.html',
    styles: [`
    ngb-progressbar {
        margin-top: 5rem;
    },
    page-header {
        display: flex;
    }
    `]
})

export class ComponentsComponent implements OnInit, OnDestroy {
    data: Date = new Date();
    date: { year: number, month: number };
    model: NgbDateStruct;

    background: string;
    backgrounds = new Array(
        'assets/img/background1.jpg'
    );
    current = 0;

    constructor() {
    }

    ngOnInit() {
        const navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.add('navbar-transparent');
        const body = document.getElementsByTagName('body')[0];
        body.classList.add('index-page');

        // setInterval(this.nextBackground.bind(this), 3000);
        this.background = this.backgrounds[0];
    }

    ngOnDestroy() {
        const navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.remove('navbar-transparent');
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('index-page');
    }

    nextBackground() {
        this.current++;
        this.current = this.current % this.backgrounds.length;
        this.background = this.backgrounds[this.current];
    }
}
